<template lang="pug">
.full-box
  DataTable(
    ref="table"
    :hasPage="false"
    resource="/usewaterIndex/list"
    :transformResponse="handleTransformResponse"
    :filterFields="[]"
    :columns="columns"
    :operateButtonList="operateButtonList")
  el-dialog.dialog-box(
    title="编辑"
    append-to-body
    :visible.sync="showDialog"
    :before-close="handleClose")
    DataForm(
      ref="form"
      v-model="formData"
      labelWidth="200px"
      type="grid"
      :gridspanNum="1"
      :formFields="formFields")
    ButtonGroup(
      slot="footer"
      :buttonList="buttonList")
</template>

<script>
// 用水分析
import cloneDeep from 'lodash/cloneDeep'
import regexs from '@/utils/regexs'

export default {
  name: 'UsewaterAnalyse',
  props: {},
  data () {
    return {
      columns: [
        { name: 'waterWorks', label: '水厂', minWidth: 300 },
        { name: 'waterIntaking', label: '取水许可证(万m³/年)', minWidth: 200 },
        { name: 'waterSupply', label: '年供水量(万m³/年)', minWidth: 200 }
      ],
      operateButtonList: [{
        label: '编辑',
        name: 'edit',
        type: 'text',
        textType: 'primary',
        func: ({ data }) => {
          this.$set(this, 'formData', cloneDeep(data))
          this.showDialog = true
        }
      }],
      showDialog: false,
      formData: {},
      formFields: [{
        name: 'waterWorks',
        label: '水厂',
        form: {
          tag: 'text'
        }
      }, {
        name: 'waterIntaking',
        label: '取水许可证(万m³/年)',
        form: {
          tag: 'number',
          rules: [regexs.required]
        }
      }, {
        name: 'waterSupply',
        label: '年供水量(万m³/年)',
        form: {
          tag: 'number',
          rules: [regexs.required]
        }
      }],
      buttonList: [{
        name: 'confirm',
        label: '保存',
        type: 'primary',
        func: this.handleSave
      }, {
        name: 'close',
        label: '关闭',
        func: this.handleClose
      }]
    }
  },
  methods: {
    getData () {
      this.$refs.table.reRenderTableData()
    },
    handleTransformResponse (response) {
      response.data = {
        total: response.data.length,
        data: response.data
      }
      return response
    },
    handleClose () {
      this.formData = {}
      this.showDialog = false
    },
    async handleSave () {
      try {
        await this.$refs.form.$refs.dataform.validate()
        const res = await this.$patch({
          url: `/usewaterIndex/update/${this.formData.id}`,
          data: {
            ...this.formData
          }
        })
        if (!res) {
          return res
        }
        this.$message.success('修改成功!')
        this.handleClose()
        this.getData()
        return 'success'
      } catch (error) {
        return error
      }
    }
  }
}
</script>

<style scoped>

</style>
